import { useTranslate } from '@tolgee/react';
import Container from '@amf/shared/components/layout/Container';
import IconAppStore from '@amf/shared/icons/IconAppStore';
import IconGooglePlay from '@amf/shared/icons/IconGooglePlay';
import { PartnerFragment } from '@amf/shared/types/graphql';
import FooterPartners from '@amf/shared/components/layout/FooterPartners';

type Props = {
  partners: PartnerFragment[];
};

export default function Footer({ partners }: Props) {
  const { t } = useTranslate();
  return (
    <div className='Footer'>
      <Container>
        <FooterPartners partners={partners} />
        <div className='Footer__row'>
          <div className='Footer__column'>
            <h2>{t('footer.billing.title')}</h2>
            <address>
              MF Kolín, z.s.
              <br />
              Kutnohorská 40
              <br />
              280 02 Kolín IV
            </address>
            <p>IČ: 21432767</p>
          </div>
          <div className='Footer__column'>
            <h2>{t('footer.contact.title')}</h2>
            <p>
              <a href='mailto:borkyliga@seznam.cz'>borkyliga@seznam.cz</a>
              <br />
              <a href='tel:+420720184504'>+420 720 184 504</a>
            </p>
          </div>
          <div className='Footer__column'>
            <h2>{t('footer.social.title')}</h2>
            <p>
              <a href='https://www.facebook.com/www.borkyliga.cz/' target='_blank' rel='noreferrer'>
                Facebook
              </a>
            </p>
            <p>
              <a href='https://www.instagram.com/mf_kolin/' target='_blank' rel='noreferrer'>
                Instagram
              </a>
            </p>
          </div>
        </div>
        <div className='Footer__apps'>
          <h2>{t('footer.apps.title')}</h2>
          <div className='Footer__appsGrid'>
            <a
              href='https://apps.apple.com/vn/app/mal%C3%BD-fotbal/id1665660869?uo=2'
              target='_blank'
              rel='noreferrer'
            >
              <IconAppStore />
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=com.amf&pli=1'
              target='_blank'
              rel='noreferrer'
            >
              <IconGooglePlay />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}
