import classNames from 'classnames';
import Container from '@amf/shared/components/layout/Container';
import { useTranslate } from '@tolgee/react';
import { useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { validateEmail } from '@amf/shared/utils/string';
import TextField from '@amf/shared/components/form/TextField';
import Button from '@amf/shared/components/button/Button';

enum Status {
  NORMAL = 'NORMAL',
  LOADING = 'LOADING',
  SENT = 'SENT',
}

export type NewsletterData = {
  email: string;
};

type Props = {
  className?: string;
};

function Newsletter({ className }: Props) {
  const { t } = useTranslate('general');
  const [status, setStatus] = useState<Status>(Status.NORMAL);
  const form = useForm<NewsletterData>({
    defaultValues: { email: '' },
  });

  const submit = useCallback(async (data: NewsletterData) => {
    try {
      setStatus(Status.LOADING);

      const response = await fetch('/api/newsletter', {
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });

      const result = (await response.json()) as {
        success?: boolean;
        error?: string;
      };
      console.log(result);

      setStatus(Status.SENT);
    } catch (e) {
      console.error(e);
      setStatus(Status.NORMAL);
    }
    console.log(data);
  }, []);

  return (
    <div className={classNames('Newsletter__container', className)}>
      <Container>
        <div className={classNames('Newsletter', className)}>
          <h2>{t('newsletter.title')}</h2>
          <div className='Newsletter__layout'>
            <div className='Newsletter__info'>
              <p>{t('newsletter.description')}</p>
            </div>
            {status === Status.SENT ? (
              <div>{t('newsletter.sent')}</div>
            ) : (
              <form onSubmit={form.handleSubmit(submit)}>
                <TextField
                  disabled={status === Status.LOADING}
                  label={'E-mail'}
                  fieldSize='small'
                  type='email'
                  error={form.formState.errors?.email?.message}
                  {...form.register('email', {
                    required: 'Vyplň prosím tvojí e-mailovou adresu.',
                    validate: value => {
                      if (!validateEmail(value)) {
                        return 'Zkontroluj prosím tvojí e-mailovou adresu.';
                      }
                      return true;
                    },
                  })}
                />
                <div>
                  <Button type='submit' variant='blue-outline' disabled={status === Status.LOADING}>
                    {status === Status.NORMAL ? 'Odebírat' : 'Načítání...'}
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Newsletter;
