export const REGEX_PHONE_NUMBER = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
export const REGEX_ZIP_CODE = /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/i;
export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const REGEX_DIC = /^[A-Z]{2}[0-9A-Z]{8,12}$/;

export const truncate = (text: string, maxLength = 40): string => {
  if (!text) return '';
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
};

export function getInitials(givenName: string, familyName: string) {
  return `${givenName.substring(0, 1)}${familyName.substring(0, 1)}`;
}

export function isNumeric(str: unknown) {
  if (typeof str != 'string') return false;
  return !isNaN(Number(str)) && !isNaN(parseFloat(str));
}

export function stringifyHtml(html: string): string {
  return html
    .replace(/<br>/gi, '\n') // New lines
    .replace(/<p.*>/gi, '\n') // Paragraphs
    .replace(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, ' $2 (Odkaz->$1) ') // Links fallback
    .replace(/<(?:.|\s)*?>/g, ''); // Other html tags
}

export function validateZipCode(code: string): boolean {
  if (!code) return false;
  return REGEX_ZIP_CODE.test(code);
}

export function validateEmail(email: string): boolean {
  if (!email) return false;
  return REGEX_EMAIL.test(email);
}

export function validateICO(ico: string): boolean {
  if (!/^\d{8}$/.test(ico)) return false;

  const digits = ico.split('').map(Number);
  const weights = [8, 7, 6, 5, 4, 3, 2];

  const sum = weights.reduce((acc, weight, i) => acc + digits[i] * weight, 0);
  let remainder = sum % 11;
  let checkDigit = remainder === 0 ? 0 : 11 - remainder;
  if (checkDigit === 10) checkDigit = 0;

  return checkDigit === digits[7];
}

export function validateDIC(dic: string, country?: string): boolean {
  if (!dic) return false;

  dic = dic.toUpperCase().replace(/\s+/g, '');
  if (!REGEX_DIC.test(dic)) return false;
  if (country) {
    if (!dic.startsWith(country.toUpperCase())) return false;
  }

  return true;
}

export function validatePhoneNumber(phone: string): boolean {
  const normalized = phone.replace(/\s+/g, '');
  const regex = /^(\+\d{1,4})?\d{9,15}$/;

  return regex.test(normalized);
}

export function generateVariableSymbol(length: number = 10): string {
  if (length < 1 || length > 10) {
    throw new Error('Variabilní symbol musí mít 1 až 10 číslic.');
  }

  let vs = '';
  for (let i = 0; i < length; i++) {
    vs += Math.floor(Math.random() * 10); // Náhodné číslo 0-9
  }

  return vs;
}
