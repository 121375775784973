import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';
import { generateHtmlId } from '@amf/shared/utils/id';

interface TextFieldProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> {
  label: string;
  accessory?: ReactNode;
  error?: string;
  children?: ReactNode;
  dark?: boolean;
  fieldSize?: 'normal' | 'small';
  loading?: boolean;
  value?: string | ReadonlyArray<string> | number | null;
}

// eslint-disable-next-line react/display-name
const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      accessory,
      placeholder,
      error,
      className,
      dark,
      children,
      fieldSize,
      loading,
      value,
      ...props
    },
    ref,
  ) => {
    const id = generateHtmlId();
    const { t } = useTranslate();
    return (
      <div
        className={classNames(
          'FormField',
          {
            'FormField--dark': dark,
            'FormField--withError': error,
            'FormField--small': fieldSize === 'small',
            'FormField--loading': loading,
          },
          className,
        )}
      >
        <input
          {...props}
          id={id}
          ref={ref}
          className='FormField__input'
          placeholder={placeholder ?? ' '}
          value={value === null ? '' : value}
        />
        <label htmlFor={id} className='FormField__label'>
          {label}
        </label>
        {accessory && <div className='FormField__accessory'>{accessory}</div>}
        {children}
        {error && <p className='FormField__error'>{t(error)}</p>}
      </div>
    );
  },
);

export default TextField;
